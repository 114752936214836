<template>
    <div id="CourseList">
        <ul class="course-list-style f20">
            <li>
                <router-link :to="basicCourseRouteUrl"
                    >基本セミナー</router-link
                >
            </li>
            <li v-if="serverRole === 'admin' || userLevel > 2">
                <router-link :to="advancedCourseRouteUrl">神学科</router-link>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'CourseList',
}
</script>
<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { ElMessageBox } from 'element-plus'
import { updateLevel } from '@/service/api'
const store = useStore()

const userLevel = ref(store.getters.getUserLevel)
const serverRole = ref(store.getters.getServerRole)

onMounted(() => {
    if (serverRole.value === 'admin') return
    if (userLevel.value === 3) {
        console.log('hihi')
        ElMessageBox.alert('', '「神学科」が追加されました', {
            confirmButtonText: 'OK',
            center: true,
        }).then(() => {
            updateLevel(4)
        })
    }
})

const basicCourseRouteUrl = computed(() => {
    if (serverRole.value === 'user') return { name: 'BasicCourseVideos' }
    else if (serverRole.value === 'admin')
        return { name: 'AdminBasicCourseVideos' }
    else return { name: 'Home' }
})
const advancedCourseRouteUrl = computed(() => {
    if (serverRole.value === 'user')
        return {
            name: 'AdvancedCourseVideos',
            params: { subCategory: '神学科・第一段階' },
        }
    else if (serverRole.value === 'admin')
        return {
            name: 'AdminAdvancedCourseVideos',
            params: { subCategory: '神学科・第一段階' },
        }
    else return { name: 'Home' }
})
</script>
<style lang="scss" scoped></style>
